import React, { Component, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Fallback from '../components/common/fallback';
import Layout from "../components/Layout/Layout";

const HomePage = React.lazy(() => import('../pages/home'));
const Service = React.lazy(() => import('../pages/service'));
const Gallery = React.lazy(() => import('../pages/gallery'));

export default class MainRouter extends Component
{
	render()
	{
		return (
			<BrowserRouter>
				<Switch>
					<Suspense fallback={<Fallback/>}>
						<Layout>
							<Route exact path="/" component={HomePage}/>
							<Route path='/service/:number' component={Service}/>
							<Route path='/gallery/' component={Gallery}/>
						</Layout>
					</Suspense>
				</Switch>
			</BrowserRouter>
		);
	}
}
