import React, { ReactNode } from "react";
import { AiFillPhone } from 'react-icons/ai';
import { BsClockHistory } from 'react-icons/bs';
import { IoLocationOutline } from 'react-icons/io5';
import { HeaderInfo } from "../HeaderInfo/Header";
import { Partners } from "../Partners/Partners";
import Styles from "./layout.module.scss"

const Layout = (
	{
		children
	}: {
		children: ReactNode
	}) =>
{
	return (
		<>
			<div className={Styles.headerWrapper}>
				<div className={Styles.header}>
					<a href={"/"}>
						<div className={Styles.logo}/>
					</a>
					<div className={Styles.headerMenu}>
						<HeaderInfo title={"Ми тут"} icon={<IoLocationOutline size={40} color={"#d9272d"}/>}>
							<a href={"https://goo.gl/maps/ykL7rQfj8Rg6casD9"} target={"_blank"}>Львів - малехів, вул. Жовківська 10а (навпроти гот. "Варшава")</a>
						</HeaderInfo>
						<HeaderInfo title={"Електронна   пошта"} icon={<BsClockHistory size={40} color={"#d9272d"}/>}>
							<a href={"mailto:llumarlviv@gmail.com"}>
								llumarlviv@gmail.com
							</a>
						</HeaderInfo>
						<HeaderInfo title={"Телефонуйте"} icon={<AiFillPhone size={40} color={"#d9272d"}/>}>
							<a href={"tel:380686577999"}>+38 068 657 7999</a>
							<a href={"tel:380503749997"}>+38 050 374 9997</a>
						</HeaderInfo>
					</div>
				</div>
			</div>

			<div className={Styles.container}>
				{children}
			</div>
			<div className={Styles.footerWrapper}>
				<div className={Styles.footer}>
					<div className={Styles.text}>Ми завжди раді оперативно надати Вам професійну допомогу,
						телефонуйте, консультація безкоштовна!
					</div>
					<a href={"/"}>
						<div className={Styles.logo}/>
					</a>
					<div className={Styles.company}>«Paint Protection Technologies» © Всі права захищені.
					</div>
				</div>
			</div>
		</>
	)
}


export default Layout