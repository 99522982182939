import React, { ReactNode } from "react";
import Styles from "./HeaderInfo.module.scss";

export const HeaderInfo = (
	{
		title,
		children,
		icon
	}: {
		title: string
		children: ReactNode
		icon: any
	}) =>
{
	return (
		<div className={Styles.headerInfo}>
			<div className={Styles.icon}>
				{icon}
			</div>
			<div>
				<div className={Styles.title}>{title}</div>
				{children}
			</div>
		</div>
	)
}
